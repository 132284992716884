<template>
    <div id="system-settings">
        <a-page-header style="border-bottom: 2px solid rgb(235, 237, 240); margin-bottom: 10px;" title="系统设置" sub-title="修改系统设置" />
        <a-modal v-model:visible="showNew" title="添加公告" @ok="addOK" @cancel="addCancel" okText="确认" cancelText="取消">
            <a-row class="rows">
                <a-col span="4" class="center">标题:</a-col>
                <a-col span="20">
                    <a-input v-model:value="newTitle" placeholder="公告标题" />
                </a-col>
            </a-row>
            <a-row class="rows">
                <a-col span="4" class="center">内容:</a-col>
                <a-col span="20">
                    <a-textarea v-model:value="newContent" placeholder="公告内容" :rows="4" show-count :maxlength="200" />
                </a-col>
            </a-row>
        </a-modal>
        <a-row>
            <a-col span="18" style="padding: 5px;">
                <a-card title="类别设置" :hoverable="true">
                    <!-- <a-row>
                        <a-col span="16">类别</a-col>
                        <a-col span="8"></a-col>
                    </a-row> -->
                    <div v-for="(item, index) in $store.state.category.category" :key="{index}">
                        <a-row v-if="item" style="padding: 5px;">
                            <a-col span="2">
                                <a-tooltip>
                                    <template #title>移除</template>
                                    <delete-outlined @click="removeCategory(item)" />
                                </a-tooltip>
                                <a-divider type="vertical" />
                                <a-tooltip>
                                    <template #title>修改</template>
                                    <edit-outlined @click="editCategory(item)" />
                                </a-tooltip>
                            </a-col>
                            <a-col span="16">{{item}}</a-col>
                        </a-row>
                    </div>
                    <a-row style="margin-top: 10px;">
                        <a-col span="8">
                            <a-input placeholder="请填入需要添加的类别" v-model:value="newRegion" />
                        </a-col>
                        <a-col span="2" style="font-size: larger;" class="center">
                            <a-tooltip v-if="!isEdit">
                                    <template #title>添加</template>
                                <plus-circle-outlined @click="addCategory" />
                            </a-tooltip>
                            <a-space v-if="isEdit">
                                <a-tooltip>
                                    <template #title>修改</template>
                                    <check-circle-outlined @click="editOK" />
                                </a-tooltip>
                                <a-tooltip>
                                    <template #title>取消</template>
                                    <close-circle-outlined @click="editCancel" />
                                </a-tooltip>
                            </a-space>
                            
                        </a-col>
                    </a-row>
                </a-card>
            </a-col>
            <a-col span="6" style="padding: 5px;">
                <a-card title="模块控制" :hoverable="true">
                    <a-row class="rows">
                        <a-col span="16" class="center">允许提交举报</a-col>
                        <a-col span="8">
                            <a-switch v-model:checked="accusationEnable" @change="changeAccusation" />
                        </a-col>
                    </a-row>
                    <a-row class="rows">
                        <a-col span="16" class="center">允许提交投诉</a-col>
                        <a-col span="8">
                            <a-switch v-model:checked="complaintEnable" @change="changeComplaint" />
                        </a-col>
                    </a-row>
                </a-card>
            </a-col>
        </a-row>
        <a-card title="公告管理" :hoverable="true" style="margin-top: 10px;">
            <!-- <a-divider /> -->
            <a-row class="rows">
                <a-col span="2">
                    <a-button type="primary" @click="addAnnouncement" style="width: 100%;">添加公告</a-button>
                </a-col>
            </a-row>
            <div style="min-height: 100px;">
                <a-empty v-if="announcements.length == 0">
                    <template #description>
                        暂无公告
                    </template>
                </a-empty>
                <a-timeline v-else>
                    <a-timeline-item v-for="(item, index) in announcements" :key="item.ID">
                        <div class="item-time">{{item.time}}</div>
                        <div class="item-title">
                            <a-row>
                                <a-col span="22">
                                    {{item.title}}
                                </a-col>
                                <a-col span="2">
                                    <a-button type="danger" ghost @click="removeAnnouncement(item.id)">删除</a-button>
                                </a-col>
                            </a-row>
                        </div>
                        <div class="item-content">{{item.content}}</div>
                    </a-timeline-item>
                </a-timeline>
            </div>
        </a-card>
    </div>
</template>

<script>
import { ExclamationCircleOutlined, DeleteOutlined, PlusCircleOutlined, EditOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue';
import { ref, onMounted, computed, watch, createVNode , defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { message, Modal } from 'ant-design-vue';
import axios from "axios";
import moment from "moment";
export default {
    components: { DeleteOutlined, PlusCircleOutlined, EditOutlined, CheckCircleOutlined, CloseCircleOutlined },
    methods: { },
    data () {
        return { };
    },
    setup() {
        const store = useStore();

        const accusationEnable = ref(true);
        const complaintEnable = ref(true);
        const newRegion = ref('');
        const isEdit = ref(false);
        const editID = ref('');

        const announcements = ref([ ]);

        const showNew = ref(false);
        const newTitle = ref('');
        const newContent = ref('');

        function addAnnouncement() {
            newTitle.value = '';
            newContent.value = '';
            showNew.value = true;
        }

        function addOK() {
            axios
                .post("/api/server/addAnnouncement", {
                    title: newTitle.value,
                    content: newContent.value
                })
                .then((res) => {
                    if (res.data.success) {
                        message.success('发布公告成功');
                        showNew.value = false;
                        get_announcements();
                    } else {
                        message.error('发布公告失败：' + res.data.msg);
                    }
                });
        }

        function addCancel() {
            newTitle.value = '';
            newContent.value = '';
            showNew.value = false;
        }

        function removeAnnouncement(ID) {
            Modal.confirm({
                title: '移除公告',
                icon: createVNode(ExclamationCircleOutlined),
                content: '确认要移除这个公告吗？该操作不能撤销',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    axios
                        .post("/api/server/deleteAnnouncement", { id: ID })
                        .then((res) => {
                            console.log(res.data);
                            if (res.data.success) {
                                message.success('移除公告成功');
                                get_announcements();
                            } else {
                                message.error('移除公告失败：' + res.data.msg);
                            }
                        });
                }
            });
        }

        function removeCategory(item) {
            var ID = null;
            for (let i in store.state.category.category) {
                if (i && store.state.category.category[i] == item) {
                    ID = i;
                    break;
                }
            }
            if (ID) {
                console.log(ID);
                Modal.confirm({
                    title: '移除类别',
                    icon: createVNode(ExclamationCircleOutlined),
                    content: '确认要移除这个类别吗？该操作不能撤销',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                        axios
                            .post("/api/server/deleteCategory", { id: ID })
                            .then((res) => {
                                console.log(res.data);
                                if (res.data.success) {
                                    message.success('移除类别成功');
                                    store.dispatch({ type: 'setCategory' });
                                } else {
                                    message.error('移除类别失败：' + res.data.msg);
                                }
                            });
                    }
                });
            }
            
        }

        function editCategory(item) {
            var ID = null;
            for (let i in store.state.category.category) {
                if (i && store.state.category.category[i] == item) {
                    ID = i;
                    break;
                }
            }
            if (ID) {
                editID.value = ID;
                newRegion.value = item;
                isEdit.value = true;
            }
        }

        function editOK() {
            axios
                .post("/api/server/updateCategory", {
                    name: newRegion.value,
                    id: editID.value
                })
                .then((res) => {
                    console.log(res.data);
                    if (res.data.success) {
                        message.success('修改类别成功');
                        isEdit.value = false;
                        newRegion.value = '';
                        editID.value = '';
                        store.dispatch({ type: 'setCategory' });
                    } else {
                        message.error('修改类别失败：' + res.data.msg);
                    }
                });
        }

        function editCancel() {
            editID.value = '';
            newRegion.value = '';
            isEdit.value = false;
        }

        function getSwitch() {
            axios
                .post("/api/server/getSubmitSwitchStatus")
                .then((res) => {
                    if (res.data.success) {
                        console.log(res.data.data.admitSubmittingAccusation)
                        accusationEnable.value = res.data.data.admitSubmittingAccusation;
                        complaintEnable.value = res.data.data.admitSubmittingComplaint;
                    } else {
                        message.error('获取模块状态失败：' + res.data.msg);
                    }
                });
        }

        function changeAccusation(enable) {
            axios
                .post("/api/server/submitSwitch", { type: 1, open: enable })
                .then((res) => {
                    if (res.data.success) {
                        message.success('操作成功')
                    } else {
                        message.error('操作失败：' + res.data.msg);
                    }
                });
        }

        function changeComplaint(enable) {
            axios
                .post("/api/server/submitSwitch", { type: 0, open: enable })
                .then((res) => {
                    if (res.data.success) {
                        message.success('操作成功')
                    } else {
                        message.error('操作失败：' + res.data.msg);
                    }
                });
        }

        function addCategory() {
            if (newRegion.value == '' || newRegion.value == '未分类') {
                message.error('新添加的类别不能为空或者为未分类');
                return;
            }
            axios
                .post("/api/server/addCategory", { name: newRegion.value })
                .then((res) => {
                    console.log(res.data);
                    if (res.data.success) {
                        message.success('添加类别成功');
                        store.dispatch({ type: 'setCategory' });
                    } else {
                        message.error('添加类别失败：' + res.data.msg);
                    }
                });
        }

        function get_announcements() {
            axios
                .post("/api/server/getAnnouncements")
                .then((res) => {
                    if (res.data.success) {
                        announcements.value = res.data.data;
                    } else {
                        message.error('获取公告失败：' + res.data.msg);
                    }
                });
        }

        onMounted(() => {
            store.dispatch({ type: 'setSelectedKeys', selectedKeys: ['Settings'] });
            store.dispatch({ type: 'setOpenKeys', openKeys: ['Settings'] });
            get_announcements();
            getSwitch();
        });

        return {
            accusationEnable,
            complaintEnable,
            announcements,
            showNew,
            newTitle,
            newContent,
            newRegion,
            isEdit,
            editID,
            addAnnouncement,
            addOK,
            addCancel,
            removeAnnouncement,
            removeCategory,
            editCategory,
            editOK,
            editCancel,
            changeAccusation,
            changeComplaint,
            getSwitch,
            addCategory
        }
    }
}
</script>

<style>
#system-settings {
    width: 100%;
    text-align: left;
}

.rows {
    margin-bottom: 10px;
    border: none;
}

.center {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
}

.item-time {
    font-size: medium;
    margin-bottom: 10px;
}

.item-title {
    font-size: large;
    margin-bottom: 10px;
    border-left: 3px solid rgb(235, 237, 240);
    padding-left: 10px;
}

.item-content {
    font-size: medium;
    color: rgb(95, 95, 95);
    padding-left: 15px;
    /* border-bottom: 1px solid rgb(235, 237, 240); */
}
</style>
